.footer-banner-container {
  background-color: #f4f9ff;
  bottom: 0px;
  cursor: pointer;
  left: 0px;
  padding-block: 0.5rem 1.85rem;
  padding-inline: 0.75rem;
  position: fixed;
  right: 0px;
  z-index: 40;
}

.footer-banner-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-inline: auto;
  max-width: 1280px;
}

.footer-banner__content {
  margin-inline: 0.5rem;
  max-width: 32rem;
  width: 100%;
}

.footer-banner__content-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
  position: relative;
  text-align: center;
}

.footer-banner__icon {
  background-color: #7dad31;
  border-radius: 9999px;
  fill: white !important;
  height: fit-content;
  left: -1rem;
  padding: 0.25rem;
  position: absolute;
  z-index: 10;
}

.footer-banner__text {
  background-color: #1a76ba;
  border-radius: 9999px;
  color: rgb(255 255 255);
  column-gap: 1rem;
  font-weight: 600;
  padding-block: 0.25rem 0.5rem;
  padding-inline: 1.5rem 2rem;
  width: 100%;
}

.footer-banner__message {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.4rem;
}

.footer-banner-6 {
  margin: auto;
}

.footer-banner__phone-text {
  background-color: #fb923c;
  border-radius: 9999px;
  bottom: -24px;
  color: rgb(255 255 255);
  font-size: 1rem;
  font-weight: bolder;
  height: fit-content;
  line-height: 1.5rem;
  padding-block: 0.25rem;
  padding-inline: 1rem;
  position: absolute;
  width: fit-content;
}

@media (min-width: 1024px) {
  .footer-banner__content-wrapper {
    gap: 1.5rem;
  }
}

@media (min-width: 768px) {
  .footer-banner-container {
    padding-bottom: 0.5rem !important;
    padding-inline: 0px !important;
  }

  .footer-banner__content {
    min-width: 100%
  }

  .footer-banner__icon {
    left: 0px;
  }

  .footer-banner__message {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .footer-banner__phone-text {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 370px) {
  .footer-banner__text {
    margin-left: 0.125rem;
  }
}

@media (min-width: 640px) {
  .footer-banner-container {
    background-color: #1a76ba;
    padding-right: 4rem;
  }

  .footer-banner__content {
    max-width: fit-content;
  }

  .footer-banner__icon {
    position: relative;
  }

  .footer-banner__text {
    display: flex;
    flex-direction: row;
    padding-bottom: 0px;
    width: fit-content;
  }

  .footer-banner__message {
    font-weight: bolder;
  }

  .footer-banner__phone-text {
    bottom: 0px;
    left: 0px;
    padding-block: 0.25rem;
    position: relative;
    white-space: nowrap;
  }
}

@media (min-width: 400px) {
  .footer-banner-container {
    padding-inline: 1rem;
  }

  .footer-banner__content {
    margin-inline: 0px;
    max-width: 95%;
  }
}