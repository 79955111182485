.aside {
  max-width: 295px;
}

.aside-wrapper {
  position: sticky;
  top: 0;
  margin-bottom: 15px;
}

.flights-content-container .aside-wrapper {
  position: sticky;
  top: 140px;
  margin-bottom: 15px;
}

@media (max-width: 766px) {
  .aside {
    margin-left: 0;
    width: 100%;
    max-width: none;
  }

  .aside-wrapper {
    width: 100%;
  }
}