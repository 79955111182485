.countdownwarning {
    z-index: 15;
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffff;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
}

.countdownwarning #insideCOuntdownWarmning {
    width: 500px;
    height: 280px;
    border-radius: 10px;
}

.countdownwarning span {
    margin: 3px;
    font-weight: bolder;
}

.countdownwarning p {
    background-color: #ffff;
    z-index: 12222;
    text-align: center;
    margin-top: 10px;
    padding: 0 104px;
}

.countdownwarning div {
    background-color: white;
}

.btn button {
    border: none;
    font-size: large;
    background-color: #1879C0;
    height: 45px;
    width: 70%;
    border-radius: 4px;
    color: white;
    position: absolute;
    bottom: 10%;
}

.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.countdownfinal {
    z-index: 15;
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffff;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
}

.countdownfinal>* {
    background-color: white;
}

.countdownfinal #insideCOuntdownWarmning {
    width: 600px;
    height: 330px;
    border-radius: 10px;
}

.countdownfinal p {
    background-color: white;
    margin-top: 15px;
    margin-bottom: 25px;
    padding-left: 100px;
    padding-right: 100px;
}

.continuePageReload {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.continuePageReload button {
    border: none;
    font-size: large;
    background-color: #1879C0;
    height: 45px;
    width: 70%;
    border-radius: 4px;
    color: white;
}

@media screen and (max-width: 700px) {
    .countdownfinal #insideCOuntdownWarmning {
        width: 80%;
        border-radius: 10px;
        height: 330px;
    }

    .countdownfinal p {
        background-color: white;
        margin-top: 15px;
        margin-bottom: 25px;
        padding-left: 8px;
        padding-right: 8px;
    }

    .countdownwarning #insideCOuntdownWarmning {
        width: 80%;
        border-radius: 10px;
    }

    .btn button {
        border: none;
        font-size: large;
        background-color: #1879C0;
        height: 40px;
        width: 60%;
        border-radius: 4px;
        color: white;
        position: absolute;
        bottom: 5%;
    }
}

@media screen and (max-width: 450px) {
    .countdownfinal #insideCOuntdownWarmning {
        width: 80%;
        border-radius: 10px;
    }

    .countdownwarning p {
        background-color: #ffff;
        z-index: 12222;
        text-align: center;
        margin-top: 6px;
        padding: 0px 28px;
    }

    .btn button {
        border: none;
        font-size: large;
        background-color: #1879C0;
        height: 40px;
        width: 60%;
        border-radius: 4px;
        color: white;
        position: absolute;
        bottom: 10%;
    }
}