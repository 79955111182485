.search-button-edit-label {
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
}

.search-button-edit-mobile{
  width: 20px;
  height: 20px;
  text-indent: -100%;
  overflow: hidden;
  font-size: 0;
  line-height: 0;
  background: #ff0;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.search-button-info{
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100vw - 160px);
  white-space: nowrap;
  font-size: 12px;
}
