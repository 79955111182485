.super-deal-modal-block-with-girl {
  background-image: url("../../../../assets/images/agent3.png");
  background-position: 100% 0;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
}

.super-deal-modal-block-response-tap.default-modal-component {
  font-size: 50px;
  line-height: 58px;
}

.same-day-modal-block-with-girl {
  background-image: url("../../../../assets/images/agent2.png");
  background-position: 100% 0;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 100%;
}

.same-day-modal-block-with-girl.one-day {
  background-image: url("../../../../assets/images/agent4.png");
}

.page-banner-response-tap.page-banner-component {
  font-size: 28px;
  line-height: 33px;
}

.same-day-modal-block-response-tap.default-modal-component {
  font-size: 26px;
  line-height: 30px;
}

.same-day-modal-block-with-girl-shadow {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 50px;
  background: linear-gradient(180deg, rgba(233, 246, 255, 0) 0%, #e9f6ff 100%);
}

.same-day-modal-block-with-girl-shadow.one-day {
  bottom: 0;
}

.same-day-modal-block-with-girl-wrapper {
  height: 100%;
  position: relative;
}

.banner-desktop-text {
  margin-right: 10px;
}

.flights-list-banner__phone-text {
  font-size: 22px;
  line-height: 24px;
  font-weight: 700;
  text-decoration: none;
}

@media (max-width: 1150px) {
  .banner-desktop-holder {
    flex-direction: column;
  }
}

@media (max-width: 1024px) {
  .super-deal-modal-block-response-tap.default-modal-component {
    font-size: 33px;
    line-height: 39px;
  }

  .page-banner-response-tap.page-banner-component {
    text-decoration: none;
  }

  .banner-desktop-holder {
    flex-direction: row;
  }
}

@media screen and (max-width: 767px) {
  .search-page-banner-holder {
    margin-bottom: 10px;
  }

  .flights-list-banner_phone-holder {
    margin-top: 3px;
  }

  .flights-list-banner__icon {
    fill: #fff;
  }

  .flights-list-banner_text {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    margin-right: 105px;
  }

  .flights-list-banner__phone-text {
    padding-left: 10px;
  }
}

@media (max-width: 374px) {
  .super-deal-modal-block-response-tap.default-modal-component {
    font-size: 26px;
    line-height: 30px;
  }
}

