.suggestion-banners {
  display: flex;
  max-width: 689px;
  justify-content: space-between;
  margin-bottom: 45px;
}

.suggestion-banners li {
  max-width: 216px;
  width: 33%;
  height: 216px;
}

.suggestion-banner-title {
  color: #FFFFFF;
  font-weight: bold;
  line-height: 22px;
}

.suggestion-banner {
  padding: 30px 15px;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  align-items: flex-end;
  box-sizing: border-box;
}

.banner-btn {
  height: 34px;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  background: none;
  color: #FFFFFF;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 14px;
}

.banner-btn img {
  margin-left: 10px;
}

@media (max-width: 860px) {

  .suggestion-banners li {
    height: 180px;
  }

  .suggestion-banner-title {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .suggestion-banners {
    display: block;
    margin: 15px 0 30px 0;
    max-width: none;
  }

  .suggestion-banners li {
    max-width: 100%;
    width: 100%;
    height: 216px;
    margin-bottom: 15px;
  }

  .suggestion-banner {
    padding: 36px 20px;
  }

  .suggestion-banner-title {
    max-width: 266px;
    font-size: 20px;
  }
}
