.payment-confirmation-modal {
  flex-direction: column;
  align-items: center;
  display: flex;
  background: white;
  width: 500px;
  height: 500px;
  border-radius: 10px;
  justify-content: center;
}

.payment-confirmation-modal>h1 {
  font-size: 32px;
}

.payment-confirmation-modal p {
  text-align: center;
  padding: 0 50px;
  line-height: 27px;
  text-align: center;
}

.payment-confirmation-modal h2 {
  margin-top: 20px;
  font-weight: normal;
  text-align: center;
}

.payment-confirmation-modal #progressBar {
  margin: 30px 0;
  height: 24px;
  width: 424px;
  background-color: #E8F2F9;
}

.wrapper {
  border-radius: 100%;
}

.label {
  display: none;
}

.container {
  background-color: #E8F2F9;
}

.timerModal {
  position: relative;
}

.book-flight-page {
  margin: 0 auto;
  max-width: 1000px;
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

.payment-copy-page {
  margin: 0 auto;
  max-width: 1000px;
  display: flex;
  justify-content: center;
  padding-top: 30px;
  pointer-events: none;
}

.book-flight-page-item {
  flex: 0 0 70%;
  margin-right: 20px;
}

.book-flight-page-aside {
  position: relative;
  flex: 0 0 30%;
}

.page-bottom-banner-component {
  cursor: pointer;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;
}

.page-bottom-banner-component .page-bottom-banner-component-text {
  font-size: 22px;
  line-height: 30px;
  font-weight: bold;
}

.book-flight-form-price-summary {
  width: 100%;
}

.book-flight-form-btn {
  font-family: 'Roboto', sans-serif;
  margin-bottom: 20px;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .book-flight-page {
    margin: 0 -10px;
  }

  .payment-copy-page {
    margin: 0 -10px;
    pointer-events: none;
  }
}

@media (max-width: 767px) {
  .book-flight-page-item {
    flex: 0 0 100%;
    margin-right: 0;
  }

  .page-bottom-banner-component .page-bottom-banner-component-text {
    font-size: 14px;
    line-height: 16px;
  }

  .page-bottom-banner-component {
    cursor: default;
  }

  .phone-response-tap {
    position: absolute;
    visibility: visible;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
  }
}

@media (min-width: 320px) and (max-width: 500px) {
  .call-us-modal-page .response-tap.modal-response-tap {
    font-size: 18px;
  }
}

@media (min-width: 320px) and (max-width: 375px) {
  .page-bottom-banner-component .page-bottom-banner-component-text {
    font-size: 12px;
  }
}

.animation {
  transform: translateY(100%);
}

.open {
  transform: translateY(100%);
  transition: transform 2s;
}

@media only screen and (max-width:550px) {
  .payment-confirmation-modal {
    flex-direction: column;
    align-items: center;
    display: flex;
    background: white;
    width: 80%;
    height: 500px;
    border-radius: 10px;
    justify-content: center;
  }

  .payment-confirmation-modal img {
    width: inherit;
    margin-bottom: 20px;
  }

  .payment-confirmation-modal #progressBar {
    width: inherit;
  }

  .payment-confirmation-modal p {
    text-align: center;
    padding: 0 20px;
    line-height: 27px;
    text-align: center;
  }
}