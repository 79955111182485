.itinerary-details {
  font-family: monospace;
}

.itinerary-details-wrapper {
  overflow-x: auto;
}

.itinerary-details-break-words {
  word-wrap: break-word;
}

@media (max-width: 600px) {
  .itinerary-details {
    font-size: 7px;
  }
}
