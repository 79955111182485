.itinerary-details-page .passenger-details-list {
  margin-bottom: 20px;
}

.itinerary-details-page .passenger-details {
  padding-bottom: 20px;
  border-bottom: 1px solid #EFF0F6;
}

.flight-summary {
  margin: 20px 0;
}

.itinerary-details-header {
  padding: 10px;
  background: #E6EFF7;
}

.itinerary-details-header img.flight-icon{
  margin-right: 10px;
  width: 15px;
}
