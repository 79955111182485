.trustpilot-footer {
  padding: 80px 65px 65px ;
}

.trustpilot-footer-title {
  margin-bottom: 55px;
}

@media screen and (max-width: 767px) {
  .trustpilot-footer {
    padding: 60px 20px;
  }

  .trustpilot-footer-title {
    margin-bottom: 40px;
  }
}
