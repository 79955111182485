.flight-list-filters {
  height: calc(100% - 65px);
  box-sizing: border-box;
}

.flight-list-filters.skeleton {
  width: 216px;
}

.modal-filters {
  position: relative;
  background-color: rgba(239,240,246,1);
}

.modal-filters .modal-filters-close-icon:before,
.modal-filters .modal-filters-close-icon:after {
  height: 20px;
}

.modal-filters-close-btn {
  position: absolute;
  top: 9px;
  right: 14px;
}

.flight-list-sorting-mobile {
  margin-bottom: 22px;
}

.sorting-label {
  color: #1879c0;
  font-size: 18px;
  line-height: 21px;
}

.modal-filter-content {
  height: calc(100% - 65px);
  padding: 0;
}

.title-filters {
  display: flex;
  justify-content: center;
  padding: 15px 0;
  background-color: #FFFFFF;

}
.title-filters.hidden {
  display: none;
}

.title-filters h3 {
  flex: 0.9;
  text-align: center;
  color: #262728;
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  line-height: 23px;
}

.airportsSubTitlePaddingTop {
    padding-top: 15px;
}

.airportsSubTitlePaddingBottom {
    padding-bottom: 15px;
}

.btn-close {
  width: 300px;
}

.btn-close.hidden {
  display: none;
}

.btn-close.show {
  display: block;
}

@media (max-width: 1024px) {
  .flight-list-filters {
    height: calc(100% - 30px);
    padding: 0 20px;
    overflow: scroll;
  }

  .filters-clear-all {
    position: absolute;
    top: 15px;
  }

  .modal-filter-content {
    height: 100%;
  }
}

@media (max-width: 767px) {
  .modal-filter-content {
    height: 100%;
  }
}
