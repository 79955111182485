.layout-item {
  min-height: 600px;
}

.aside-right {
  flex: 1;
}

.flights-content-container .aside {
  width: 217px;
}

.aside-left {
  flex: 1;
}

.flights-content-container {
  margin-top: 30px;
}

.flights-content {
  position: relative;
  width: 689px;
  min-height: 600px;
  z-index: 3;
}

.aside-left .flight-list-filters {
  width: 216px;
}

.flights-content-side-block {
  flex: 0 0 calc(50% - 580px);
  margin-top: 60px;
  margin-bottom: 15px;
}

.page-zoom {
  position: sticky;
  top: 20px;
  padding-right: 24px;
}

.search-panel-tablet-holder{
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 15;
}

@media (max-width: 1350px) {
  .page-zoom {
    display: none;
  }
}

@media screen and (max-width: 1180px) {
  .aside-left {
    flex-basis: 22.5%;
  }

  .flights-content{
    flex-basis: 60%;
    width: auto;
  }

  .aside-right{
    flex-basis: 17.5%;
  }
}

@media (max-width: 1024px) {
  .aside-left .flight-list-filters {
    max-width: 217px;
  }
}

@media screen and (max-width: 1024px) {
  .flight-page--search-modile {
    position: -webkit-sticky;
    position: sticky;
    margin: 20px -15px 0;
    top: 0;
    z-index: 4;
  }

  .flight-page--search-modile .filters-button {
    min-width: 126px;
    padding: 8px;
  }
}

@media screen and (max-width: 767px){
  .flight-page--search-modile {
    margin: 0 -10px;
  }

  .flights-content-mobile{
    margin-top: 10px;
  }

  .calendar-holder-mobile{
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 380px){
  .flight-page--search-modile .filters-button {
    padding: 8px;
  }
}

.flights-currency-warning {
  position: sticky;
  margin: 0px -10px;
  color: #ffdf00;
  line-height: 1px;
  top: 50px;
  z-index: 5;
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .flights-currency-warning {
    top: 240px;
  }
}

@media only screen and (min-width: 1280px) {
  .flights-currency-warning {
    top: 110px;
  }
}
