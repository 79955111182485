.container-header {
  border-bottom: 1px solid #EFF0F6;
  padding: 22px 24px;
  display: flex;
}

.container-header h1 {
  margin-left: 11px;
}

@media (max-width: 767px) {
  .container-header {
    padding: 14px 16px;
  }

  .flight-container-header.container-header {
    flex-wrap: wrap;
  }
}
