.info-row {
  display: flex;
  max-width: 389px;
  justify-content: space-between;
  margin-bottom: 4px;
  font-size: 13px;
}

.capitalized {
  text-transform: capitalize;
}

.info-row p {
  width: 75%;
}

.stretch .info-row p {
  width: auto;
}

.label {
  color: #6E7A83;
}

@media (max-width: 1050px) {
  .stretch .info-row {
    max-width: 100%;
  }
}
