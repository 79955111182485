.banner_container {
    position: fixed;
    background-color: #ffdf00;
    min-height: 40px;
    z-index: 100;
    left: 0;
    right: 0;
    display: flex;
    padding: 5px;
    justify-content: space-between;
}

.banner__container--item {
    margin: 10px;
    font-weight: bold;
}

.banner__container--close-btn {
    color: black;
    background: transparent;
    border: 0;
    font-size: 21px;
    font-weight: bold;
    border-radius: 10px;
    height: 20px;
}
.banner-link {
    font-size: 18px;
}