.indent {
  padding: 20px 24px;
}

.indent.none {
  padding: 0;
}

.indent.topP {
  padding: 20px 0 0 0;
}

.indent.bottomP {
  padding: 0 0 20px 0;
}

.indent.leftP {
  padding: 0 0 0 24px;
}

.indent.rightP {
  padding: 0 24px 0 0;
}

.indent.marginT {
  margin: 6px 0 0 0;
}

.indent.marginB {
  margin: 0 0 6px 0;
}

@media (max-width: 1050px) {
  .indent {
    padding: 18px 16px;
  }

  .indent.topP {
    padding: 18px 0 0 0;
  }

  .indent.bottomP {
    padding: 0 0 10px 0;
  }

  .indent.leftP {
    padding: 0 0 0 16px;
  }

  .indent.rightP {
    padding: 0 16px 0 0;
  }

}
