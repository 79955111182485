.component-container {
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0 1px 4px 0 rgba(16, 35, 49, 0.15);
  margin: 0 18px 22px 0;
}

.component-container.large {
  max-width: 689px;
}

.component-container.small {
  max-width: 295px;
  padding: 16px 15px;
  margin-bottom: 10px;
  margin-right: 0;
}

.component-container.stretch {
  padding: 0;
}

.component-container header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;
}

@media (max-width: 1050px) {
  .component-container.stretch {
    max-width: 100%;
  }
}

@media screen and (max-width: 860px) {

  .book-with-us-banner li img {
    margin-right: 4px;
  }

  .component-container {
    margin-right: 0;
    border-radius: 0;
  }

  .component-container header {
    display: block;
    margin-bottom: 18px;
  }
}
