.booking-confirmation-header {
  padding: 22px 24px 33px 24px;
}

.booking-information {
  padding: 0 24px 32px 24px;
  font-size: 13px;
}

.booking-information h3 {
  margin-bottom: 16px;
}

.booking-information .booking-itinerary {
  margin-bottom: 20px;
  display: flex;
}

.booking-itinerary .passenger-details {
  display: block;
}

.passenger-details p {
  margin-bottom: 5px;
}

.custom-icon {
  opacity: 0.4;
  margin-right: 9px;
}

.flight-icon {
  height: 12.58px;
  width: 12px;
  vertical-align: text-bottom;
}

.back-flight-icon {
  transform: rotate(180deg);
}

.passenger-icon {
  width: 10px;
  height: 11px;
}

.information-content {
  font-size: 15px;
  margin-bottom: 22px;
  line-height: 18px;
}

.booking-itinerary .booking-flightsegment {
  display: block;
  margin-right: 50px;
}

.name-on-card {
  word-break: break-word;
}

@media (max-width: 767px) {
  .booking-information .booking-itinerary {
    justify-content: space-between;
  }

  .booking-itinerary .booking-flightsegment,
  .booking-itinerary .passenger-details {
    width: 50%;
  }

  .passenger-details p {
    word-break: break-all;
  }
}