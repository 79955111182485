html {
  height: 100%;
}

body {
  font-family: Roboto;
  height: 100%;
}

#root {
  height: 100%;
}

ul {
  list-style: none;
}

button {
  outline: none;
  cursor: pointer;

}

strong {
  font-weight: bold;
}

h1 {
  font-size: 26px;
  font-weight: 500;
  line-height: 30px;
}

h2 {
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
}

h3 {
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
}

h4 {
  font-size: 15px;
  font-weight: 500;
}

.error-message {
  color: #FF401A;
  font-size: 15px;
  font-weight: bold;
}

.blue {
  color: #48A0E0;
}

.red {
  color: #FF401A;
}

.orange {
  color: #FF8000;
}

.green-light {
  color: #7EAD2D;
}

.green {
  color: #21994A;
}

.secondary {
  color: #6E7A83;
}

.text.small {
  font-size: 11px;
}

.text.big {
  font-size: 15px;
}

.text.huge {
  font-size: 20px;
  line-height: 24px;
}

.text.bold {
  font-weight: bold;
}

.text.semi-bold {
  font-weight: 500;
}

.text.justify {
  text-align: justify;
}

a {
  font-size: 13px;
  color: #1879C0;
  line-height: 15px;
}

a:hover {
  text-decoration: underline;
}

.stretch-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.column-1 {
  width: 33%;
}

.column-2 {
  width: 60%;
}

.mobile-visible {
  display: none;
}

.string {
  height: 1px;
  width: 15%;
  background-color: #BCC7CF;
  margin-top: 2px;
}

.payment-error {
  padding-bottom: 30px;
}


@media screen and (max-width: 767px) {

  h1 {
    font-size: 20px;
  }

  .text.note {
    font-size: 13px;
  }

  .information-content {
    line-height: 22px;
  }

  .successful-confirmation-container {
    max-width: 100%;
  }
}

@media screen and (max-width: 600px) {

  .mobile-visible {
    display: block;
  }

  .mobile-hidden {
    display: none;
  }
}