.price-summary-block {
  padding: 16px 16px 11px;
}

.price-summary-block .secondary-container {
  padding-left: 16px;
  padding-right: 16px;
}

.cancellation-line p {
  margin-bottom: 4px;
}

.mobile-style {
  display: none;
}

.price-summary-block-label {
  padding: 9px 16px;
}

.book-flight-page-aside .cancellation-block,
.book-flight-page-item .cancellation-block {
  display: inline-flex;
}

.cancellation-line {
  position: relative;
}

.flight-card-cancellation-block {
  padding: 9px 24px;
}

.book-flight-page-item .flight-card-cancellation-block {
  background-color: rgba(72, 160, 224, 0.1);
  border-left: 8px solid #1879C0;
}

.price-summary-message {
  border-bottom: 1px solid #F5F6F7;
  padding-left: 16px;
  padding-right: 16px;
}

@media (max-width: 1050px) {
  .mobile-style {
    display: block;
  }

  .component-container.small {
    margin: 0 18px 22px 0;
  }
}

@media (max-width: 860px) {
  .mobile-style {
    display: block;
  }

  .component-container.small {
    margin-right: 0;
  }
}

@media (max-width: 1024px) {
  .book-flight-page-item .flight-card-cancellation-block {
    margin: -9px -24px 9px;
  }
}