.passenger-list li {
  border-bottom: 1px solid #EFF0F6;
  padding: 24px;
}

@media (max-width: 767px) {
  .passenger-list li {
    padding: 22px 16px 28px 16px;
  }
}
