.subscription-container {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 5px 0 rgba(16, 35, 49, .15);
  box-sizing: border-box;
  max-width: 700px;
  margin-top: 20px;
  padding: 10px 20px;
}

.ui.checked.checkbox,
.ui.checkbox {
  display: flex;
  margin-block: 4px;
  align-items: flex-start;
}

.ui.checked.checkbox input:focus-visible,
.ui.checkbox input:focus-visible {
  outline: white auto 0.5px;
}

.subscription-text {
  font-size: 13px;
  line-height: 18px;
}