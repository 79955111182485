html {
  scroll-behavior: smooth;
}

.ReactModal__Content.modal-filters {
  margin: 0;
  height: 100%;
  max-height: 100%;
  width: 395px;
}

.modal-search-overlay {
  margin-top: 0;
}

.call-now-modal-response-tap.call-now-modal-component {
  font-size: 68px;
  line-height: 90px;
}

.call-now-modal-review.call-now-modal-component {
  font-size: 25px;
  color: #2B4E73;
  line-height: 33px;
}

.loading-state {
  margin-top: 20px;
}

.search-page-banner-response-tap.search-page-banner-component {
  font-size: 38px;
  line-height: 51px;
}

.search-page-banner-response-tap-title.search-page-banner-component-title {
  font-size: 20px;
  line-height: 51px;
}

.page-bottom-banner-component {
  cursor: pointer;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
}

.result-wrapper button {
  z-index: 1;
  width: 100%;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .modal-filters {
    margin: 0;
    height: 100%;
    min-height: 100%;
  }

  .call-now-modal-response-tap.call-now-modal-component {
    font-size: 38px;
    line-height: 40px;
  }

  .flights-content-loading-text {
    position: static;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 1024px) {
  .flight-page--search-modile .flight-page--button-modile {
    background: transparent;
    border-color: transparent;
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media (max-width: 767px) {
  .modal-filters {
    width: 100%;
    height: 100%;
    min-height: 100%;
  }

  .flights-content-loading-text {
    position: static;
    font-size: 18px;
    margin-bottom: 10px;
  }

  .call-now-modal-trust-pilot {
    display: block;
  }

  .call-now-modal-response-tap.call-now-modal-component {
    font-size: 28px;
    line-height: 40px;
  }

  .call-now-modal-review.call-now-modal-component {
    font-size: 17px;
    width: 60%;
  }

  .flight-page--search-modile .flight-page--button-modile {
    padding-left: 0;
    padding-right: 8px;
    text-align: left;
  }

  .page-bottom-banner-component {
    cursor: default;
  }

  .phone-response-tap {
    position: absolute;
    visibility: visible;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
  }
}

@media (min-width: 501px) and (max-width: 600px) {
  .search-page-banner-response-tap-wrapper.search-page-banner-component-wrapper {
    flex-wrap: wrap;
  }

  .search-page-banner-response-tap.search-page-banner-component {
    font-size: 32px;
    line-height: 51px;
  }

  .search-page-banner-response-tap-title.search-page-banner-component-title {
    flex: 0 0 100%;
    font-size: 20px;
    line-height: 51px;
  }
}

@media screen and (max-width: 500px) {
  .search-page-banner-response-tap-wrapper.search-page-banner-component-wrapper {
    flex-wrap: wrap;
  }

  .search-page-banner-response-tap.search-page-banner-component {
    font-size: 22px;
    line-height: 38px;
  }

  .search-page-banner-response-tap-title.search-page-banner-component-title {
    flex: 0 0 100%;
    font-size: 20px;
    line-height: 38px;
  }

  .call-us-modal-page .response-tap.modal-response-tap {
    font-size: 18px;
  }

  .flights-content-loading-text {
    font-size: 15px;
  }
}