.secondary-container {
  border-radius: 4px;
  padding: 9px 24px;
}

.secondary-container p {
  font-size: 13px;
}

.secondary-container.transparent {
  background: #FFFFFF;
}

.secondary-container.primary {
  background-color: #ECF4FA;
  max-width: 641px;
  padding: 12px 10px;
}

.secondary-container.dim {
  background-color: #F5F6F7;
  max-width: 100%;
  margin-top: 5px;
}

.secondary-container .icon {
  margin-right: 10px;
}

.secondary-container.center {
  margin: 22px auto 0 auto;
  max-width: 93%;
}

.secondary-container.middle {
  display: flex;
}

.secondary-container.justify {
  justify-content: space-between;
}

@media (max-width: 767px) {
  .secondary-container.primary {
    padding: 8px 10px;
  }

  .secondary-container.dim {
    padding: 16px;
  }
}
