.countdown-container {
    font-size: 10px;
    color: #1879C0;
}
.countdown-minutes {
    font-size: 37px;
}
.countdown-seconds {
    font-size: 37px;
}
.countdown-content-holder {
    flex-direction: column;
    justify-content: space-between;
}
.countdown-content-holder-mobile {
    flex-direction: column;
    justify-content: space-around;
    
}
.countdown-text-content {
    font-size: 15px;
}

.countdown-container-mobile {
    display: none !important;
}
.countdown-left {
    font-size: 15px;
    color: black;
    margin-left: 5px;
}
.sticky {
    top: 0;
    position: sticky;
    z-index: 10;
    margin: 0 !important;
}